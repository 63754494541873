<template>
  <div id="request" class="pb-6">

      <v-app-bar flat inverted-scroll scroll-threshold="200">
        <v-app-bar-title>
        <v-chip small color="primary" class="mr-1">#{{request.id}}</v-chip>
          {{request.subject}}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon class="ml-1" v-on="on" size="14" @click="copyToClipboard($event)">mdi-content-copy</v-icon>
            </template>
            <span>依頼番号と件名をクリップボードにコピーします。</span>
          </v-tooltip>
        </v-app-bar-title>
      </v-app-bar>

      <v-toolbar height="auto" flat extension-height="auto">
        <v-toolbar-title>
          <v-btn rounded @click="$router.go(-1)" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
          <v-chip small color="primary" class="mr-1">#{{request.id}}</v-chip>
          {{request.subject}}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon class="ml-1" v-on="on" size="14" @click="copyToClipboard($event)">mdi-content-copy</v-icon>
            </template>
            <span>依頼番号と件名をクリップボードにコピーします。</span>
          </v-tooltip>
        </v-toolbar-title>
        <v-spacer />
        <v-btn text @click="show_detail = !show_detail" :input-value="show_detail"><v-icon small>mdi-unfold-more-horizontal</v-icon>詳細</v-btn>
        <template v-slot:extension>
          <v-sheet v-show="show_detail" class="px-2 py-5 flex-grow-1">
          <v-expand-transition>
            <v-expansion-panels v-show="show_detail" v-model="detailPanels">
              <!-- basic info -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span>
                    基本情報<v-btn icon small class="edit-member ml-2" @click.stop="editBasicInfo()" v-if="detailPanels === 0"><v-icon small>mdi-pencil</v-icon></v-btn>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense>
                    <v-col cols="12" md="4">
                      <h4>公開希望日時</h4>
                      <p>{{ request.release_date }} {{ request.release_time }}</p>
                    </v-col>
                    <v-col cols="12" md="4" v-if="request.plan_mst && request.plan_mst.contract_model == 'point'">
                      <h4>ポイント倍率</h4>
                      <p>{{ request.point_rate }} 倍</p>
                    </v-col>
                    <v-col cols="12" md="4" v-if="request.point_allocate_date ">
                      <h4>計上日</h4>
                      <p>{{ request.point_allocate_date }}</p>
                    </v-col>
                    <v-col cols="12" v-if="request.request_admin_memo">
                      <h4>依頼社内メモ<popup-hint>クライアントには表示されません</popup-hint></h4>
                      <p>{{ request.request_admin_memo }}</p>
                    </v-col>
                  </v-row>

                  <v-dialog max-width="500px" persistent v-model="crud.basicInfo.edit">
                    <v-card>
                      <v-card-title>基本情報の編集</v-card-title>
                      <v-divider />
                      <v-card-text class="pt-3">
                        <v-row dense>
                          <v-col cols="12" sm="4">
                            <v-text-field label="公開希望日時" type="date" v-model="request.release_date"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" class="d-flex">
                            <v-text-field type="time" v-model="request.release_time"></v-text-field>
                            <popup-hint>※何も入力しなければ「指定なし」として登録されます。</popup-hint>
                          </v-col>
                          <v-col cols="12" sm="6" class="d-flex" v-if="request.plan_mst && request.plan_mst.contract_model == 'point'">
                            <v-select :items="point_rate" label="ポイント倍率" v-model="request.point_rate" class="mr-1" suffix="倍"></v-select>
                            <popup-hint>「当日対応であれば通常の2倍」などのポイント倍率設定が可能です</popup-hint>
                          </v-col>
                          <v-col cols="12" sm="6" class="d-flex">
                            <v-text-field label="計上日" type="date" v-model="request.point_allocate_date" class="mr-1"></v-text-field>
                            <popup-hint>ポイントや利用回数を消費する日付です。</popup-hint>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea outlined label="依頼社内メモ" v-model="request.request_admin_memo" rows="4" hide-details=""></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="cancelEditBasicInfo()">キャンセル</v-btn>
                        <v-btn class="primary" @click="updateBasicInfo()">保存</v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- status history -->
              <v-expansion-panel>
                <v-expansion-panel-header>ステータス履歴</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table dense disable-sort :headers="status_history.table_headers" :items-per-page="-1" :items="request.status_histories" class="elevation-0" hide-default-footer>
                    <template v-slot:[`item.change_at`]="{ item }">
                      {{ item.change_at | moment("YYYY/MM/DD HH:mm") }}
                    </template>
                    <template v-slot:[`item.working_time`]="{ item }">
                      {{ item.working_hours }}時間{{ item.working_minutes }}分
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small @click="editStatusHistory(item)">mdi-pencil</v-icon>
                    </template>
                    <template v-slot:[`body.append`]>
                      <tr>
                        <td colspan="2"></td>
                        <td class="text-right">合計</td>
                        <td colspan="2">{{ TotalOperationTime }}</td>
                      </tr>
                    </template>
                  </v-data-table>

                  <!-- crud dialog -->
                  <v-dialog v-model="status_history.dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-center pa-1">
                        <v-btn fab small color="primary" v-bind="attrs" v-on="on" @click="newStatusHistory"><v-icon>mdi-table-row-plus-after</v-icon></v-btn>
                      </div>
                    </template>

                    <v-card>
                      <v-card-title v-if="status_history.editedIndex === -1">ステータス追加</v-card-title>
                      <v-card-title v-else>ステータス編集</v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-container>
                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-select v-model="status_history.editedItem.status_mst" :items="AllStatus" item-text="status" item-value="id" label="ステータス" persistent-hint return-object single-line></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-select v-model="status_history.editedItem.assigned_login" item-text="name" :items="submit_login_id_list" label="担当者" persistent-hint return-object single-line></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field v-model="status_history.editedItem.change_at" label="変更日時" type="datetime-local"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-text-field v-model="status_history.editedItem.working_hours" label="作業時間" suffix="時間" class="mr-2" type="number" min="0"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-text-field v-model="status_history.editedItem.working_minutes" suffix="分" type="number" min="0"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeStatusHistoryDialog()">キャンセル</v-btn>
                        <v-btn v-if="statusCrudMode == 'edit'" color="primary" @click="updateStatusHistory(status_history.editedItem.id)">保存</v-btn>
                        <v-btn v-promise-btn v-else color="primary" @click="createStatusHistory">保存</v-btn>
                        <v-spacer></v-spacer>
                        <confirm-dialog @accept="deleteStatusHistory(status_history.editedItem.id)" v-if="status_history.editedIndex !== -1">
                          <template v-slot:title>このステータス履歴を削除しますか？</template>
                          <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
                          <template v-slot:activator="{ on }">
                            <v-btn icon @click="on"><v-icon>mdi-delete-forever</v-icon></v-btn>
                          </template>
                        </confirm-dialog>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- service info-->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span>サービス</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <RequestService ref="RequestService" :request_id="request.id" :point_rate="request.point_rate" @add="addRequestService" @remove="removeRequestService" @edit="updateRequestService" @change="changeRequestService" />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- contract partner -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span>
                    依頼関係者<v-btn icon small class="edit-member ml-2" @click.stop="editMember()" v-if="detailPanels === 3"><v-icon small>mdi-pencil</v-icon></v-btn>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row no-gutters>
                    <v-col v-for="(item, i) in request_member" :key="i" cols="12" sm="6" md="4" class="py-1">
                      <LoginAvatar :user="getAccountData(item)" :size="32" class="mr-1" />
                      <span v-if="getAccountData(item)">{{ getAccountData(item).name }}</span>
                      <span v-else>削除済みユーザー</span>
                    </v-col>
                  </v-row>
                  <v-dialog max-width="400px" persistent v-model="crud.member.edit">
                    <v-card>
                      <v-card-title>依頼関係者の編集</v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-select chips v-model="request_member" :items="selectAccount" label="" placeholder="依頼関係者の選択" multiple hide-details></v-select>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="cancelEditMember()">キャンセル</v-btn>
                        <v-btn class="primary" @click="updateMember()">保存</v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- タスク所有者 -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span>
                    タスク所有者<v-btn icon small class="edit-assigned_login ml-2" @click.stop="editAssignedLogin()" v-if="detailPanels === 4"><v-icon small>mdi-pencil</v-icon></v-btn>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="4" class="py-1">
                      <template v-if="request.assigned_login">
                        <LoginAvatar :user="request.assigned_login" :size="32" class="mr-1" />
                        <span>{{ request.assigned_login.name }}</span>
                      </template>
                    </v-col>
                  </v-row>
                  <v-dialog max-width="400px" persistent v-model="crud.assigned_login.edit">
                    <v-card>
                      <v-card-title>タスク所有者の編集</v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-select v-model="request_assigned_login" :items="selectRequestAssignedLogins" label="" placeholder="タスク所有者の選択" hide-details></v-select>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="cancelEditAssignedLogin()">キャンセル</v-btn>
                        <v-btn class="primary" @click="updateAssignedLogin()">保存</v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels>
          </v-expand-transition>
          </v-sheet>
        </template>
      </v-toolbar>

      <v-container id="timeline" style="position:relative">
        <v-row>
          <v-col>
            <!-- Request -->
            <v-card>
                <v-card-text class="pa-3 pa-md-5">
                  <!-- <div class="text-right text-body-2">{{request.create_at | moment(defaultDateFormat) }}</div> -->
                  <div class="d-flex flex-column flex-md-row">
                    <div class="flex-grow-0 text-center mb-4 flex-md-shrink-0 col-md-2">
                      <template v-if="request.submit_login">
                        <LoginAvatar :user="request.submit_login" :size="64" />
                        <div class="text-caption mt-2">
                          {{request.create_at | moment(defaultDateFormat) }}<br>
                          {{request.submit_login.name}}
                        </div>
                      </template>
                    </div>
                    <div class="flex-glow-1 pl-md-3 col-md-10">
                      <span v-text="request.request_content" v-linkified></span>
                    </div>
                  </div>
                </v-card-text>
                <template v-if="request.file_attachments && request.file_attachments.length">
                <v-card-actions class="px-4 py-1">
                  <v-chip-group column>
                    <template v-for="file in request.file_attachments">
                      <FileAttachmentChip :key="file.id" :file="file"></FileAttachmentChip>
                    </template>
                  </v-chip-group>
                </v-card-actions>
                </template>
                <v-divider />
                <v-card-text class="px-4 py-2">
                  <emoji-reaction :request-id="request.id"/>
                </v-card-text>
            </v-card>

            <!-- Timeline -->
            <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
              <template v-for="(item, i) in request.timeline">
                <div :key="i">
                <v-timeline-item v-if="item.comment" :right="item.submit_login.is_admin_user" :left="item.submit_login.is_end_user" :class="{end_user: item.submit_login.is_end_user, admin_user: item.submit_login.is_admin_user}">
                  <template v-slot:icon>
                    <LoginAvatar :user="item.submit_login" :size="($vuetify.breakpoint.smAndDown ? 32 : 48)" />
                  </template>
                  <template v-slot:opposite>
                    <div class="text-caption">
                      {{item.create_at | moment(defaultDateFormat) }}
                      <span v-if="item.update_at">[edited]</span>
                    </div>
                    <v-icon small class="mr-1" v-show="item.send_mail_flag" >mdi-email-check</v-icon>
                    <span v-if="item.submit_login.delete_at" class="text-caption">{{ item.submit_login.name }}(削除済み)</span>
                    <span v-else>{{ item.submit_login.name }}</span>
                  </template>
                  <v-card class="elevation-2">
                    <v-card-text class="pa-3">
                      <div class="text-caption mb-2 d-md-none">
                        <span class="mr-2" >{{item.create_at | moment(defaultDateFormat) }}</span>
                        <v-icon small class="mr-1" v-show="item.send_mail_flag" >mdi-email-check</v-icon>{{ item.submit_login.name }}
                      </div>
                      <div v-show=canEditTimelineComment(item)>
                        <span v-text="item.comment" :ref="'timeLine-comment-span' + item.id" v-linkified></span>
                        <textarea :value="item.comment" :ref="'timeLine-comment-area' + item.id" class="edit-timeline-comment" @keydown=timelineCommentResize :style=contentAreaStyle disabled></textarea>
                        <v-layout>
                          <v-spacer />
                          <div :ref="'timeLine-comment-buttons' + item.id" style="display: none;">
                            <v-btn small text @click="editTimelineCommentCancel(item)">キャンセル</v-btn>
                            <v-btn small class="primary" @click="saveTimeLineComment(item)">保存</v-btn>
                          </div>
                          <v-icon small class="ml-2" @click="editTimeLimeComment(item)">mdi-pencil</v-icon>
                        </v-layout>
                      </div>
                      <span v-show=!canEditTimelineComment(item) v-text="item.comment" v-linkified></span>
                    </v-card-text>
                    <v-card-actions v-if="item.file_attachments.length" class="px-4 pb-1 pt-0">
                      <v-chip-group column>
                        <template v-for="file in item.file_attachments">
                          <FileAttachmentChip :key="file.id" :file="file"></FileAttachmentChip>
                        </template>
                      </v-chip-group>
                    </v-card-actions>
                    <v-divider />
                    <v-card-text class="px-4 py-2">
                      <emoji-reaction :comment-id="item.id"/>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <div v-if="item.status_mst" class="changed-status">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip small v-bind="attrs" v-on="on" :color="item.assigned_login.user_type == 'admin' ? 'primary':'secondary' ">{{ item.status_mst.status }}</v-chip>
                    </template>
                    <span>{{ item.assigned_login.name }}</span>
                  </v-tooltip>
                </div>
                <!-- タスク所有者 -->
                <v-timeline-item v-if="showAssignedLoginInTimeline && (i === request.timeline.length - 1)" :right="request.assigned_login.is_admin_user" :left="request.assigned_login.is_end_user" :class="{end_user: request.assigned_login.is_end_user, admin_user: request.assigned_login.is_admin_user}">
                  <template v-slot:icon>
                    <LoginAvatar :user="request.assigned_login" :size="($vuetify.breakpoint.smAndDown ? 32 : 48)" />
                  </template>
                  <template v-slot:opposite>
                    <span class="d-block text-caption">タスク所有者</span>
                    <span>{{ request.assigned_login.name }}</span>
                  </template>
                </v-timeline-item>
                </div>
              </template>

              <!-- Action Button -->
              <div class="add text-center">
                  <v-menu top min-width="200" nudge-left="58%" transition="slide-y-transition" offset-y nudge-bottom="-10">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn x-large fab dark color="primary" elevation="5" v-bind="attrs" v-on="on"><v-icon>mdi-comment-plus</v-icon></v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="(item, index) in AllStatusWithoutCancel" :key="index" @click="showStatusChangeDialog({state_from: request.current_status, state_to: item, request_id: request.id})">
                        <v-list-item-title>{{ item.status }}</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="showStatusChangeDialog({request_id: request.id})">
                        <v-list-item-title>コメント</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
              </div>
            </v-timeline>
          </v-col>
        </v-row>
      </v-container>

    <!-- 依頼情報 -->
    <v-navigation-drawer right clipped app width="210" mobile-breakpoint="700" id="sub-nav">
      <v-list class="pa-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle class="mb-2">公開希望日時
              <v-chip x-small outlined class="px-1 ml-2" v-if="diffDate(request.release_date) > 0">
                <template v-if="diffDate(request.release_date) === 0">本日</template>
                <template v-else>{{ diffDate(request.release_date) }}日後</template>
              </v-chip>
            </v-list-item-subtitle>
            <v-list-item-title class="text-h6">{{ request.release_date | moment("YYYY/M/D(ddd)") }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ request.release_time }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="request.plan_mst && request.plan_mst.contract_model == 'point'">
          <v-list-item-content>
            <v-list-item-subtitle>利用ポイント</v-list-item-subtitle>
            <div>
              <span class="text-h6">{{ requestFinalPoint }}</span><span class="unit">Pt</span>
                <v-chip x-small outlined class="px-1 ml-2" v-if="request.point_rate && request.point_rate != 1">
                <span class="point-rate">
                {{ totalPoint }}<span class="unit">Pt</span> × {{ request.point_rate }}<span class="unit">倍</span></span>
                </v-chip>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-content>
            <v-stepper v-model="status_stepper_step" flat vertical non-linear v-for="(item, i) in AllStatusWithoutCancel" :key="i">
            <v-stepper-step editable :step="item.step" @click="showStatusChangeDialog({state_from: request.current_status, state_to: item, request_id: request.id})">
              {{ item.status }}
            </v-stepper-step>
            </v-stepper>
          </v-list-item-content>
        </v-list-item>

      </v-list>
      <template v-slot:append>
        <template v-if="request.submit_login">
        <v-list class="customer">
          <v-list-item>
            <v-list-item-content>
              <v-menu top offset-y open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title v-bind="attrs" v-on="on" class="mb-2">
                    {{ request.end_user.end_company_name }}
                  </v-list-item-title>
                </template>
                <v-list max-height="800" max-width="400" dense>
                  <template  v-if="end_user_admin_memo.memo">
                    <v-list-item>
                      <v-list-item-content>
                        <pre>{{ end_user_admin_memo.memo }}</pre>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                  </template>
                  <v-list-item :to="{ name: 'admin:client_show', params: { id: request.end_user_id } }">
                    <v-list-item-title>
                      <v-list-item-icon><v-icon small>mdi-domain</v-icon></v-list-item-icon>クライアント詳細
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item :to="{ name: 'admin:request_index', params: { fillter: 'all' }, query: { end_user_id: request.end_user_id, end_company_name: request.end_user.end_company_name } }">
                    <v-list-item-title>
                      <v-list-item-icon><v-icon small>mdi-checkbox-multiple-marked-outline</v-icon></v-list-item-icon>依頼一覧
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-list-item-subtitle v-if="request.plan_mst">
                <router-link :to="{ name: 'admin:client_show', params: { id: request.end_user_id, show_contract: true } }">
                <img v-if="request.plan_mst && request.plan_mst.contract_model == 'point'" src="@/assets/ico/ico_point.svg" style="vertical-align:top" width="14" />
                <img v-else src="@/assets/ico/ico_unlimited.svg" width="14" style="vertical-align:top" />{{ request.plan_mst.plan_name }}
                </router-link>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-avatar size="32" class="mr-1" v-if="request.submit_login"><LoginAvatar :user="request.submit_login" :size="32" /></v-list-item-avatar>
            <v-list-item-title class="text-body-2">{{ request.submit_login.name }}</v-list-item-title>
            <v-btn small icon :href="`mailto:${request.submit_login.email}`" v-if="request.submit_login.email"><v-icon small>mdi-email</v-icon></v-btn>
            <v-btn small icon :href="`tel:${request.submit_login.phone}`" v-if="request.submit_login.phone"><v-icon small>mdi-cellphone</v-icon></v-btn>
          </v-list-item>
        </v-list>
        </template>
      </template>
    </v-navigation-drawer>

    <!-- ダイアログ用スロット -->
    <componentSlot :is="componentSlot.component" :params="componentSlot.params" v-on="componentSlot.event" />
  </div>
</template>

<script>
import StatusChange from '@/components/StatusChange.vue'
import RequestService from '@/components/RequestService.vue'
import axios from '@/plugins/axios'
import FileAttachmentChip from '@/components/FileAttachmentChip.vue'
import ConfirmDialog from '../../components/ConfirmDialog.vue'
import PopupHint from '../../components/PopupHint.vue'
import moment from 'moment'
import { mapState } from 'vuex'
import EmojiReaction from '../../components/EmojiReaction.vue'
import LoginAvatar from '@/components/LoginAvatar.vue'

export default {
  name: 'request',
  inheritAttrs: false,
  components: {
    StatusChange,
    RequestService,
    FileAttachmentChip,
    ConfirmDialog,
    PopupHint,
    EmojiReaction,
    LoginAvatar
  },
  data: function () {
    return {
      statusCrudMode: 'new',
      show_detail: false,
      status_stepper_step: null,
      detailPanels: null,
      componentSlot: { // ステータス変更コンポーネント読み込み用
        component: '',
        params: {
          type: '',
          request: null,
          state_from: null,
          state_to: null
        },
        event: {
          close: this.hideStatusChangeDialog,
          reload: this.readDataFromAPI
        }
      },
      request: {}, // 依頼情報
      status_history: {
        dialog: false,
        editedIndex: -1,
        editedItem: {
          status_mst: {},
          assigned_login: {},
          change_at: moment().format('YYYY-MM-DDTHH:mm'),
          working_hours: 0,
          working_minutes: 0
        },
        defaultItem: {
          status_mst: {},
          assigned_login: {},
          change_at: moment().format('YYYY-MM-DDTHH:mm'),
          working_hours: 0,
          working_minutes: 0
        },
        table_headers: [
          { text: 'ステータス', value: 'status_mst.status' },
          { text: '日時', value: 'change_at' },
          { text: '担当者', value: 'assigned_login.name' },
          { text: '作業時間', value: 'working_time' },
          { text: '', value: 'actions', width: 20 }
        ]
      },
      end_user: [],
      end_user_admin_memo: {
        dialog: false,
        memo: ''
      },
      crud: {
        basicInfo: {
          edit: false,
          restore: {
            release_date: '',
            release_time: '',
            point_rate: null,
            point_allocate_date: '',
            request_admin_memo: ''
          }
        },
        member: {
          edit: false,
          restore: []
        },
        assigned_login: {
          edit: false,
          restore: ''
        }
      },
      request_member: [],
      request_assigned_login: '',
      requester_accounts: [],
      completeStatusId: 7
    }
  },
  watch: {
    resize () {
      this.timelineCommentResize()
    }
  },
  created: function () {
    this.request.id = this.$route.params.id
    this.readDataFromAPI()
  },
  mounted: function () {
    const interval = setInterval(() => {
      if (Array.from(document.getElementsByClassName('edit-timeline-comment')).length !== 0) {
        this.timelineCommentResize()
        clearInterval(interval)
      }
    }, 50)
  },
  methods: {
    copyToClipboard: function (event) {
      const text = (event.shiftKey !== true ? `#${this.request.id} ${this.request.subject}` : `s_${this.request.id}_${this.request.subject}`)
      navigator.clipboard.writeText(text)
        .then(() => {
          console.log('copied!')
        })
        .catch(e => {
          console.error(e)
        })
    },
    showStatusChangeDialog: function (option) {
      this.componentSlot.component = 'StatusChange'
      this.componentSlot.params = option
      this.componentSlot.params.request = this.request
      return false
    },
    hideStatusChangeDialog: function () {
      this.componentSlot.component = ''
      this.componentSlot.props = {}
      this.status_stepper_step = this.request.current_status.step
    },
    readDataFromAPI: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${this.request.id}`)
        .then(res => {
          this.request = res.data
          // console.log(res.data)
          this.end_user_admin_memo.memo = this.request.end_user.end_user_admin_memo
          this.setDeleteAccountName(this.request.submit_login)
          this.status_stepper_step = this.request.current_status.step
          this.request_member = []
          this.request_assigned_login = (this.request.assigned_login ? this.request.assigned_login?.id : '')
          this.request.involved_logins.end_user.forEach(item => {
            if (item.end_user && item.id !== this.request.submit_login.id) {
              if (!this.request_member.includes(item.id)) {
                this.request_member.push(item.id)
              }
            }
          })
          this.getEndUserLogins()
          const interval = setInterval(() => {
            if (Array.from(document.getElementsByClassName('edit-timeline-comment')).length !== 0) {
              this.timelineCommentResize()
              clearInterval(interval)
            }
          }, 50)
          document.title = document.title.replace('依頼詳細', '【#' + this.request.id + '】' + this.request.subject)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.scrollTimeline()
        })
      // 依頼ポイントコンポーネントが存在していれば読み込む
      if (this.$refs.RequestService) {
        this.$refs.RequestService.readDataFromAPI()
      }
    },
    getEndUserLogins: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user/${this.request.end_user.id}`)
        .then(res => {
          this.requester_accounts = res.data.logins
        })
        .catch(err => {
          console.log(err)
        })
    },
    addRequestService: function (requestService) {
      const self = this
      const formData = new FormData()
      formData.append('point_mst_id', requestService.point_mst.id)
      formData.append('fix_count', requestService.fix_count)
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${self.request.id}/request_point`, formData)
        .then(res => {
          self.$store.commit('setFlashSuccess', 'サービスを追加しました。')
          self.$refs.RequestService.readDataFromAPI()
        })
        .catch(err => {
          self.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
        })
    },
    updateRequestService: function (requestService) {
      const self = this
      // console.log(requestService)
      if (requestService.toEditId) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('point_mst_id', requestService.point_mst.id)
        formData.append('fix_count', requestService.fix_count)
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request_point/${requestService.toEditId}`, formData)
          .then(res => {
            // console.log(res.data)
            self.$store.commit('setFlashSuccess', 'サービスを変更しました。')
            self.$refs.RequestService.readDataFromAPI()
          })
          .catch(() => {
            self.$store.commit('setFlashError', '入力内容に不備があります。入力内容をご確認ください。')
          })
      }
    },
    removeRequestService: function (requestService) {
      const self = this
      // console.log(requestService)
      if (requestService.id) {
        axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request_point/${requestService.id}`)
          .then(res => {
            self.$store.commit('setFlashSuccess', 'サービスを削除しました。')
            self.$refs.RequestService.readDataFromAPI()
          })
          .catch(() => {
            self.$store.commit('setFlashError', '入力内容に不備があります。入力内容をご確認ください。')
          })
      }
    },
    changeRequestService (requestService) {
      this.request.request_points = requestService
    },
    newStatusHistory () {
      this.statusCrudMode = 'new'
      this.status_history.dialog = true
      this.status_history.editedItem.change_at = moment().format('YYYY-MM-DDTHH:mm')
    },
    editStatusHistory (item) {
      this.statusCrudMode = 'edit'
      this.status_history.editedIndex = this.request.status_histories.indexOf(item)
      this.status_history.editedItem = Object.assign({}, item)
      this.status_history.editedItem.change_at = moment(this.status_history.editedItem.change_at).format('YYYY-MM-DDTHH:mm')
      this.status_history.dialog = true
    },
    createStatusHistory () {
      const _this = this
      const formData = new FormData()
      formData.append('status_mst_id', _this.status_history.editedItem.status_mst.id ? _this.status_history.editedItem.status_mst.id : '')
      formData.append('assigned_login_id', _this.status_history.editedItem.assigned_login.id ? _this.status_history.editedItem.assigned_login.id : '')
      formData.append('change_at', _this.status_history.editedItem.change_at ? _this.status_history.editedItem.change_at : '')
      formData.append('working_hours', _this.status_history.editedItem.working_hours ? _this.status_history.editedItem.working_hours : 0)
      formData.append('working_minutes', _this.status_history.editedItem.working_minutes ? _this.status_history.editedItem.working_minutes : 0)
      formData.append('request_id', _this.request.id)
      return new Promise(function (resolve, reject) {
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/status_history`, formData)
          .then((res) => {
            // console.log(res.data)
            _this.readDataFromAPI()
            _this.$store.commit('setFlashSuccess', 'ステータスを保存しました。')
            resolve(res)
            _this.closeStatusHistoryDialog()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
            reject(err)
            _this.closeStatusHistoryDialog()
          })
      })
    },
    updateStatusHistory (id) {
      const _this = this
      const formData = new FormData()
      formData.append('_method', 'PUT')
      formData.append('status_mst_id', _this.status_history.editedItem.status_mst.id ? _this.status_history.editedItem.status_mst.id : '')
      formData.append('assigned_login_id', _this.status_history.editedItem.assigned_login.id ? _this.status_history.editedItem.assigned_login.id : '')
      formData.append('change_at', _this.status_history.editedItem.change_at ? _this.status_history.editedItem.change_at : '')
      formData.append('working_hours', _this.status_history.editedItem.working_hours ? _this.status_history.editedItem.working_hours : 0)
      formData.append('working_minutes', _this.status_history.editedItem.working_minutes ? _this.status_history.editedItem.working_minutes : 0)
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/status_history/${id}`, formData)
        .then((res) => {
          // console.log(res.data)
          _this.readDataFromAPI()
          _this.$store.commit('setFlashSuccess', 'ステータスの変更を保存しました。')
          this.closeStatusHistoryDialog()
        })
        .catch(err => {
          _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
        })
    },
    deleteStatusHistory (id) {
      // console.info('deleteStatusHistory')
      const _this = this
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/status_history/${id}`)
        .then(res => {
          _this.$store.commit('setFlashSuccess', 'ステータスを削除しました。')
        })
        .catch(() => {
          _this.$store.commit('setFlashError', 'エラー・ステータスを削除しませんでした。')
        })
        .finally(() => {
          _this.readDataFromAPI()
          this.closeStatusHistoryDialog()
        })
    },
    closeStatusHistoryDialog () {
      this.status_history.dialog = false
      this.$nextTick(() => {
        this.status_history.editedItem = Object.assign({}, this.status_history.defaultItem)
        this.status_history.editedIndex = -1
      })
    },
    editMember () {
      this.crud.member.restore = this.request_member
      this.crud.member.edit = true
    },
    updateMember () {
      // console.info('update logic')
      const self = this
      const formData = new FormData()
      for (const loginId of self.request_member) {
        formData.append('secondary_submit_login[]', loginId)
      }
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${self.request.id}/update_submit_logins`, formData)
        .then(res => {
          self.$store.commit('setFlashSuccess', '依頼関係者を更新しました')
          self.readDataFromAPI()
        })
        .catch(err => {
          this.request_member = this.crud.member.restore
          self.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
        })
      this.crud.member.edit = false
    },
    cancelEditMember () {
      this.request_member = this.crud.member.restore
      this.crud.member.edit = false
    },
    editAssignedLogin () {
      this.crud.assigned_login.restore = this.request_assigned_login
      this.crud.assigned_login.edit = true
    },
    updateAssignedLogin () {
      const self = this
      const formData = new FormData()
      formData.append('assigned_login_id', self.request_assigned_login)
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${self.request.id}/update_assigned_login`, formData)
        .then(res => {
          self.$store.commit('setFlashSuccess', 'タスク所有者を更新しました')
          self.readDataFromAPI()
        })
        .catch(err => {
          this.request_assigned_login = this.crud.assigned_login.restore
          self.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
        })
      this.crud.assigned_login.edit = false
    },
    cancelEditAssignedLogin () {
      this.request_assigned_login = this.crud.assigned_login.restore
      this.crud.assigned_login.edit = false
    },
    editBasicInfo () { // その他の情報編集
      this.crud.basicInfo.restore.release_date = this.request.release_date
      this.crud.basicInfo.restore.release_time = this.request.release_time
      this.crud.basicInfo.restore.point_rate = this.request.point_rate
      this.crud.basicInfo.restore.point_allocate_date = this.request.point_allocate_date
      this.crud.basicInfo.restore.request_admin_memo = this.request.request_admin_memo
      this.crud.basicInfo.edit = true
    },
    updateBasicInfo () {
      const _this = this
      const formData = new FormData()
      formData.append('_method', 'PUT')
      formData.append('release_date', _this.request.release_date ? _this.request.release_date : '')
      formData.append('release_time', _this.request.release_time ? _this.request.release_time : '')
      formData.append('point_rate', _this.request.point_rate ? _this.request.point_rate : -1)
      formData.append('point_allocate_date', _this.request.point_allocate_date ? _this.request.point_allocate_date : '')
      formData.append('request_admin_memo', _this.request.request_admin_memo ? _this.request.request_admin_memo : '')
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${this.request.id}`, formData)
        .then((res) => {
          // console.log(res.data)
        })
        .catch(err => {
          _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
        })
      this.crud.basicInfo.edit = false
    },
    cancelEditBasicInfo () {
      this.request.release_date = this.crud.basicInfo.restore.release_date
      this.request.release_time = this.crud.basicInfo.restore.release_time
      this.request.point_rate = this.crud.basicInfo.restore.point_rate
      this.request.point_allocate_date = this.crud.basicInfo.restore.point_allocate_date
      this.request.request_admin_memo = this.crud.basicInfo.restore.request_admin_memo
      this.crud.basicInfo.edit = false
    },
    getAccountData: function (id) {
      if (!id || !this.requester_accounts.length) {
        return { name: '', photo: { file_url: '' } }
      }
      var acc = this.requester_accounts.find((v) => v.id === id)
      if (acc === undefined) {
        acc = false
      }
      return acc
    },
    setDeleteAccountName: function (item) {
      if (item.delete_at != null) {
        item.name = item.name + '(削除済み)'
      }
    },
    diffDate: function (expireDate, today) {
      return (moment(expireDate).diff(today, 'days'))
    },
    scrollTimeline () {
      this.$nextTick(() => {
        var element = document.documentElement
        var bottom = element.scrollHeight - element.clientHeight
        // console.log(element.scrollHeight)
        window.scrollTo({ top: bottom, left: 0, behavior: 'smooth' })
      })
    },
    canEditTimelineComment (item) {
      if (item.submit_login_id === this.$store.state.login_user.id && this.request.current_status_id !== this.completeStatusId) {
        return true
      }
      return false
    },
    // タイムラインコメント編集メソッド
    timelineCommentResize () {
      const htmlEditComments = document.getElementsByClassName('edit-timeline-comment')
      const editComments = Array.from(htmlEditComments)
      editComments.map(function (editComment) {
        editComment.style.height = 'auto'
      })
      this.$nextTick(() => {
        editComments.map(function (editComment) {
          editComment.style.height = editComment.scrollHeight + 'px'
        })
      })
    },
    editTimeLimeComment (item) {
      const commentAreaRef = 'timeLine-comment-area' + item.id
      const contentButtonsRef = 'timeLine-comment-buttons' + item.id
      const commentSpanRef = 'timeLine-comment-span' + item.id
      this.$refs[contentButtonsRef][0].style.display = 'block'
      this.$refs[commentAreaRef][0].style.display = 'block'
      this.$refs[commentSpanRef][0].style.display = 'none'
      this.timelineCommentResize()
      this.$refs[commentAreaRef][0].disabled = false
      this.$refs[commentAreaRef][0].focus()
    },
    changeDisableTimeLineComment (item) {
      const commentAreaRef = 'timeLine-comment-area' + item.id
      const contentButtonsRef = 'timeLine-comment-buttons' + item.id
      const commentSpanRef = 'timeLine-comment-span' + item.id
      this.$refs[commentAreaRef][0].disabled = true
      this.$refs[contentButtonsRef][0].style.display = 'none'
      this.$refs[commentAreaRef][0].style.display = 'none'
      this.$refs[commentSpanRef][0].style.display = 'block'
    },
    saveTimeLineComment (item) {
      const commentAreaRef = 'timeLine-comment-area' + item.id
      if (item.comment === this.$refs[commentAreaRef][0].value) {
        this.changeDisableTimeLineComment(item)
        return
      }
      if (this.$refs[commentAreaRef][0].value.length === 0) {
        this.$refs[commentAreaRef][0].value = item.comment
        this.timelineCommentResize()
        this.changeDisableTimeLineComment(item)
        return
      }
      const formData = new FormData()
      formData.append('comment', this.$refs[commentAreaRef][0].value)
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request_comment/${item.id}/update`, formData)
        .then(res => {
          console.log(res.data)
          this.readDataFromAPI()
          this.$store.commit('setFlashSuccess', 'コメントを編集しました。')
        })
        .catch(err => {
          this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
        })
      this.changeDisableTimeLineComment(item)
    },
    editTimelineCommentCancel (item) {
      const commentAreaRef = 'timeLine-comment-area' + item.id
      this.$refs[commentAreaRef][0].value = item.comment
      this.timelineCommentResize()
      this.changeDisableTimeLineComment(item)
    }
  },
  computed: {
    ...mapState(['point_rate']),
    submit_login_id_list: function () {
      const list = []
      if ([0, 4, 6].indexOf(this.status_history.editedItem.status_mst.flag) >= 0) {
        this.request.involved_logins.end_user.forEach(item => {
          list.push({
            name: item.name,
            id: item.id
          })
        })
      } else {
        if (this.request.involved_logins?.admin_company) {
          this.request.involved_logins.admin_company.forEach(item => {
            list.push({
              name: item.name,
              id: item.id
            })
          })
        }
      }
      return list
    },
    selectAccount: function () {
      var rtn = []
      for (const elm of this.requester_accounts) {
        if (elm.id !== this.request.submit_login.id) {
          const items = {}
          items.text = elm.name
          items.value = elm.id
          rtn.push(items)
        }
      }
      return rtn
    },
    selectRequestAssignedLogins: function () {
      const list = []
      list.push({
        text: 'なし',
        value: ''
      })
      if (this.request.involved_logins) {
        this.request.involved_logins.end_user.forEach(item => {
          list.push({
            text: item.name,
            value: item.id
          })
        })
        this.request.involved_logins.admin_company.forEach(item => {
          list.push({
            text: item.name,
            value: item.id
          })
        })
      }
      return list
    },
    defaultDateFormat: function () {
      return this.$store.getters.defaultDateFormat
    },
    AllStatus: function () {
      return this.$store.getters.getAllStatus
    },
    AllStatusWithoutCancel: function () {
      return this.AllStatus?.filter(row => row.is_cancel !== true)
    },
    TotalOperationTime: function () {
      var totalH = 0
      var totalM = 0

      for (const elem of this.request.status_histories) {
        totalH += elem.working_hours
        totalM += elem.working_minutes
      }
      totalH += Math.floor(totalM / 60)
      totalM = totalM % 60

      return `${totalH}時間${totalM}分`
    },
    totalPoint: function () {
      let total = 0
      for (const item of this.request.request_points) {
        total += parseInt(item.fix_count)
      }
      return total
    },
    requestFinalPoint () {
      return (!this.request.point_rate) ? this.totalPoint : this.totalPoint * this.request.point_rate
    },
    currentStatus () {
      return this.request.current_status?.status
    },
    contentAreaStyle () {
      return {
        width: '100%',
        overflow: 'hidden',
        resize: 'none',
        display: 'none'
      }
    },
    showAssignedLoginInTimeline () {
      return (this.request.assigned_login)
    }
  }
}
</script>

<style lang="scss" scoped>

.v-toolbar__title {
  white-space: inherit;
}

.v-stepper--vertical .v-stepper__step{
  padding: 10px 5px;
}

.v-sheet.v-stepper{
  padding-bottom: 0;
}

.v-card__text{
  white-space: pre-line;
  font-size: 1rem;
}

.changed-status{
  margin: 16px 0 40px 0;
  position: relative;
  &:before{
    content: "";
    display: block;
    height: 1px;
    border-top: 1px dotted #000;
  }
  span{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.customer{
  a{
    text-decoration: none;
    color: #000000de;
    &:hover{
      text-decoration: underline;
    }
  }
}
</style>
